import React from 'react'

export const testimonials = [
    {
        name: "JP",
        body:
            <>
                <p>
                    “I’ve been using 302skincare now for four months, seeing Eva regularly for facials and consultations, and the results have been nothing short of dramatic. Since I was 13, and up until four months ago, my reality was bumpy, uneven, oily and congested cystic acne on my face, neck and jaw line. When Eva first introduced 302 to me, I was a little sceptical since I’d never heard of it. Eva took the time to thoroughly explain to me how 302 works and why it would be a good fit for me. She applied the product on me during my Ultrasound Facial and explained exactly what she was doing so I could do it myself at home. After the facial, she recommended three products (that were surprisingly inexpensive) and described how to use each of these “tools”. Within the first month of using 302, my family started commenting on how my skin had changed, and today, you would never believe I used to have the skin I had – and for so long. I’m currently only using 302’s recommended “acne treatments” but I’ve noticed a difference in so much more than just my acne; my skin tone has improved, my pores have tightened, the lines in my forehead and around the eyes, have shrank, and definitely the feel has completely improved– it is soft and smooth for the first time in my life. Every morning I am waking up to healthier and better looking skin. I can’t thank Eva enough for introducing me to the product and tailoring a regimen specific to my needs!”
                </p>
            </>
    },
    {
        name: "BD",
        body: <>
            <p>
                “I was introduced to 302 skincare six months ago, by Eva a professional esthetician from Essence of Beauty. I tend to be skeptical at the beginning as all the product I have tried in the past where expensive, demanding, and at the end not working.
            </p>
            <p>
                I am very pleased with the 302 line. I used properly and did stay consistent with the products for 6 months. I noticed a magical improvement of my skin in tone and texture, I have had no bad effects. Products lasted very long, so I consider it very affordable. I mainly like the fact that it is easy to use, gentle and efficient with the time.
            </p>
            <p>
                Some people noticed a change without knowing what is was until I explained to them. I like the fact the change is discreet. I recommended.”
            </p>
        </>
    },
    {
        name: "Chelsea",
        body: <>
            <p>
                ”Eva is my skin savior!! I am 33 years old and have suffered from chronic mild-moderate acne-rosacea for over 10 years now, and it was starting to have a really detrimental effect on my self-esteem.  I started seeing Eva six months ago for treatment of my acne, and have been receiving monthly facials from her ever since.  I have also been religiously following her skincare guidance at home between visits. Not only have I seen a HUGE reduction in my breakouts, but my skin is also so healthy and radiant!! My pore size has shrunk, my scars are fading, my fine lines are less apparent, and the overall texture, tone, and color of my skin has changed dramatically. I trust Eva. She is incredibly knowledgeable, skilled, and always professional. If you have struggled with a chronic skin problem and want to see results, I encourage you to go see Eva, and simply do what she says- it is working for me!
            </p>
            <p>
                Thank you Eva”
            </p>
        </>
    },
    {
        name: "Johnathan B",
        body: <>
            <p>
                “When I first came to Eva my skin was overwhelmed by blackheads, whiteheads, scars and redness. I wasted copious amounts of money on antibiotics, every topical imaginable, accutane treatments and only witnessed progress after one of Eva’s facials. I began my treatments less than a year ago and my skin is now near perfection. The facials I received along with carefully selected products have cleared my skin, erased scars and illuminated my face in a way I never believed possible. The acne pitted face of my past not only brought insecurity it held me captive in a cell of depression. Eva has changed my life entirely and my face is now one of envy. Her treatments and intensive knowledge of the skin are regenerative, non abrasive and shockingly impressive. She is a transformative ray of sunshine in the dark and stormy skies of skin imperfection. She is the only person I will let treat my face and I strongly encourage anyone with any skin type to meet Eva and witness her ways of greatness.”
            </p>
        </>
    },
    {
        name: "Christine",
        body: <>
            <p>
                “Eva takes a very individualised and systematic approach to skin care based on the specific needs of her clients. For many years I tried to focus on skin care through nutrition and exercise, and while there’s no doubt that these elements are essential, I really had no clue what a tremendous difference the proper topical treatment could have. Eva’s approach has successfully addressed persistent rosacea and acne, and has noticeably improved the health of my skin – it’s like different skin entirely. I find Eva truly passionate about what she does, and consequentially she’s well-researched and an expert in her field. She’s an excellent teacher and extends the effectiveness of her treatments to the home through thorough careful instruction on product use. I’m sincerely grateful for the lasting difference Eva has made in my skin. She’s earned every one of her loyal clientele through quality of care and results”.
            </p>
        </>
    },
    {
        name: "Anna",
        body: <>
            <p>
                “I was referred to Eva at Essence of Beauty from a very close friend. Her rave reviews convinced me to try a facial. I’ve had many facials in the past, but I must say, this was the most relaxing facial I had ever had. My favorite part is the fact it is not hurried — but the calm, relaxing and methodical method Eva used during that luxurious facial. The products were fabulous, and the scents were calming. Not only will I return on a regular basis, but give gift certificates to my friends and family!”
            </p>
        </>
    },
    {
        name: "Klava Z",
        body: <>
            <p>
                “I found Essence of Beauty and Eva because I was looking to buy high quality all natural skincare in Ottawa. And I am so happy that I did. Eva is the first specialist who truly made a difference for my skin. I have had an overly oily, sensitive and acneic skin my whole life. Being a makeup artist myself I have had access to all kinds of great skincare and I’ve tried so many different products; went to see a few dermatologists and estheticians, researched about various treatments of acne for a long time. Eva accessed my skin at our first consultation and proposed a treatment and my skin has been getting better and better ever since. I went from having to always wear a foundation to cover up my skin because I felt self-conscious about it to being able not to have to wear makeup at all. My skin is more balanced now, doesn’t flare up with redness because the products Eva recommended are geared towards sensitive skin. And mostly important my acne is almost all gone and so are the scars and pigmentation from old acne! I still get breakouts once in a while for hormonal reasons, but it 90% less than what I used to have. And also my skin is more healthy and strong now, so that it heals much faster after a pimple. I’ve never had my skin look and feel so great since I was a teenager! It makes such a difference in how I feel about myself too! I think the biggest difference is that Eva was able not just help me with acne but to improve the overall health of my skin. This way I don’t have to worry about acne coming back! Eva is more knowledgeable in skincare than any other specialists I’ve seen in North America. She also stocked up her spa with the best natural skincare on the market. I’ve been recommending her to all my friends, especially the ones with problem skin. And I wouldn’t hesitate to recommend her to anyone. I know that she truly cares about her clients and constantly educating herself in all new techniques.”
            </p>
        </>
    },
    {
        name: "Linda C",
        body: <>
            <p>
                “I’ve been excited about the improvement in my skin problems since I started facials with Eva, as well as using her line of holistic skin care products.  I didn’t start until my late 50’s, I wish I had started sooner, but I kept delaying it.  Then when my skin broke out with acne rosacea at 57, I knew I had to do something.  The improvement is quite dramatic, and as an added bonus I think the wrinkle lines between my eyebrows have been reduced!  I encourage all women to start seeing Eva by your 50th birthday, if not sooner, and you will be pleased with the results!”
            </p>
        </>
    },
    {
        name: "SM",
        body: <>
            <p>
                “I can’t believe I waited this long to try a facial. Originally I had thought about getting a facial once every few months, but after having one done I am treating my skin every month. I am astounded at the quality of Eva’s services. To top it all off, she is very knowledgeable, using best organic products and promotes a holistic approach to wellness. I’m so glad I found this spa.”
            </p>
        </>
    },
    {
        name: "Maria",
        body: <>
            <p>
                “I started going to Essence of Beauty in hopes of helping my acne problem. I was ready to go on antibiotics to clear up my face as a last resort. Thankfully, with the facials that I received from Eva along with product recommendations, my face cleared up….I couldn’t be happier. I will continue to get facials here and plan to try out some of their other treatments. I have also recommended Eva to friends and family.”
            </p>
        </>
    },
    {
        name: "Tina",
        body: <>
            <p>
                “I have been seeing Eva at Essence of Beauty since August of 2009. It is now nearing the end of February and my skin is CRYSTAL CLEAR. Eva really cares about my skin and doesn’t push expensive products on me when it comes time to check out. As a matter of fact, her facial prices are so reasonable that I can actually afford to take care of my skin! Not to mention she uses TOP OF THE LINE products that are very effective. In the past, my skin was so bad I refused to leave the house. And Eva has totally changed that part of my life! I hope any acne suffers who come across my testimonial will try the Ultrasound Facial with Eva because it will really change your life! I’ve been to two other spas for acne facials and none compare to this! I will definitely be back! Thanks!”
            </p>
        </>
    },
    {
        name: "Deborah T",
        body: <>
            <p>
                “Just wanted to say thanks to Eva! It has only been a few days since my acne facial and my skin is so soft and 100 times better than it was when I first came in. I’ve been to two other spas for acne facials and none compare to this! I also love the sample products. I will definitely be back! Finally, clear skin is in my future! Thanks Eva!!”
            </p>
        </>
    },
    {
        name: "Renee",
        body: <>
            <p>
                “Eva,
            </p>
            <p>
                Just wanted to send you a note about how much I like my products 302skincare.  I was in a mall 2 weeks ago and as you know, malls have many mirrors. I used to shy away from looking at myself, tired of seeing the redness and uneven skin tone, as well as my bumpy and oily forehead. Well, two weeks ago, I caught a glimpse of myself and wow the difference from before! My skin appears so soft and even, no redness, I looked like an angel if I can say so myself :-)… It is so thrilling! Looking forward to even more improvements!
            </p>
            <p>
                Thank you again for introducing me to these fabulous products!”
            </p>
        </>
    },
    {
        name: "Laura Marshall",
        body: <>
            <p>
                “I am very impressed with the results that I’m seeing with both ultrasound and facial infusion treatments performed by Eva at Essence of Beauty. I am still in the middle of my treatments (I believe I’ve had 4 of a series of 6) but I have already noticed a change in my skin. I have oily skin and was prone to frequent breakouts (2-3 pimples per week). After seeing Eva for several months, I am noticing that I have more clarity in my skin and the oiliness has decreased slightly. Most importantly, my acne is getting much better! Instead of 2-3 pimples per week, I am now down to 2-3 pimples per month (maximum). This is very exciting to me and I have noticed that my confidence is increasing as my skin is improving. The facial products that I bought through Eva – while expensive – are well worth it and I wouldn’t consider using anything else. They also last for a long time (5-6 months +). I am definitely pleased that I found Essence of Beauty.”
            </p>
        </>
    },
    {
        name: "Joana",
        body: <>
            <p>
                “I can’t believe I waited this long to try a facial by Eva. It was the most amazing experience! Originally I had thought about getting a facial once every few months, but after having one done be her I know I need this every month. I have never encountered someone as thorough as Eva – I am astounded at the quality her services. To top it all off, she is very knowledgeable, using best organic products and promotes a holistic approach to wellness. I’m so glad I found this spa!”
            </p>
        </>
    },
    {
        name: "KF",
        body: <>
            <p>
                “After being so happy with the results of Herbal Green Peel, I thought I would like to try facial cupping. The very first session I saw and felt a difference. At almost 60 years old, my tone and color could use some help and that is what happened. The results after just a couple of sessions were noticeable. The tone felt firmer and the deep lines less. The overall color seemed to even out. It’s a wonderful way to wake up the cells and clean the surface skin. I love the results and very happy I decided to try it! It is a wonderful way to take care of the skin most exposed to the elements.”
            </p>
        </>
    },
    {
        name: "Nancy B",
        body: <>
            <p>
                “After 16 years of fighting with my acne I realize that there is a treatment that suits me. I’ve tried everything! From lotions to creams, tetracycline, Pro-Activ….even Acutane, nothing worked…well until now, after 2 Green Peel treatments (and using 302skincare products) the results are AMAZING!! I can see my skin at its best… no makeup! Wow. Thanks Eva.”
            </p>
        </>
    },
    {
        name: "Michelle",
        body: <>
            <p>
                “My skin is dramatically different and looks and feels like it did 6 years ago! The pores all over my skin are much finer and some of the fine lines have practically disappeared! I’ve had a deep frown line for years and amazingly that looks much less pronounced. Thanks’ to Herbal Green Peel.”
            </p>
        </>
    },
    {
        name: "TJ",
        body: <>
            <p>
                “I have been one of Eva’s clients for about a year now, and I have had the most wonderful experience at her Spa. Eva works very hard to identify the individual needs of her clients and to go above and beyond what other esthetician do to meet each one their needs. I first went to Eva because I had a bad experience at another salon with an esthetician. She fixed the mistakes that had been previously made and made me want to come back to her because she was so knowledgeable and caring. I now compete in pageants, and thanks Eva’s professionalism, knowledge, and at-home tips, my appearances have improved.”
            </p>
        </>
    },
    {
        name: "Trisha",
        body: <>
            <p>
                “Essence of Beauty came highly recommended from a friend when I was looking for an esthitician in the Downtown Ottawa area. I used to get facials in Montreal and finding Essence of Beauty near home was a miracle. I have received a range of services from her and have never been disappointed. I feel completely comfortable and relaxed every time I come in for an appointment. She even managed to get my skin back on track through her services and wonderful products.”
            </p>
        </>
    },
    {
        name: "Wendy C",
        body: <>
            <p>
                “I’ve had acne since I was about ten and now at 37 thanks to Eva, my skin is clear and has beautiful tone. We started with some acne facials and an outstanding line of products then moved on to a series of peels. For the first time I consider my skin touchable, soft and clear She’s done wonders for me and I’d recommend her services to anyone who wants beautiful skin at any age.”
            </p>
        </>
    },
    {
        name: "Olga S",
        body: <>
            <p>
                “Eva has been nurturing my skin every month for about eight years. I am daily complemented on the condition of my skin and I love the 302skincare products. It’s good for the Soul!! Treat yourself!”
            </p>
        </>
    },
    {
        name: "Marina P",
        body: <>
            <p>
                “During the time Eva has been my primary skin care provider and since starting a treatment program with her, not only has the condition of my skin dramatically improved, but Eva has also taken great care to teach me proper skin care techniques.”

            </p>
        </>
    },
    {
        name: "Roberta",
        body: <>
            <p>
                “I have been a loyal monthly client for roughly four years. Periodically, we break from our regular monthly treatments to do a series of Facial Infusion. Recently, I ran into a friend of mine I hadn’t seen for a couple of months. She told me how fabulous my skin looked and actually thought that I had gotten a facelift. This made me feel good. Thanks Eva!”

            </p>
        </>
    },
    {
        name: "Veronica",
        body: <>
            <p>
                “Eva is wonderful. I have never been a big fan of facials and to be honest thought they were a waste of time-but Eva was able to save me a dermatologist visit and make my skin look radiant. She is also very honest and will tell you if you need to change something with your makeup or skin care routine.”
            </p>
        </>
    },
    {
        name: "Barbara",
        body: <>
            <p>
                “Eva has continued to be extremely helpful with my skin care needs. Having had acne problems for years, she has worked with me to find the product that is right for my skin. As many have noted, she is very friendly, kind, and takes care to know her clients. Even when I’m away at school, she is available to help me out with any skin care advice which I greatly appreciate.

            </p>
            <p>
                I highly recommend Eva Skin Care!
            </p>
            <p>
                Eva has greatly improved the quality of my skin in just 3 visits. I finally have enough moisture in my skin, I now have fewer fine lines and less sagging of my skin. I HIGHLY RECOMMEND HER!”
            </p>
        </>
    },
    {
        name: "DS",
        body: <>
            <p>
                “I am really impressed with Vascu-Lyse ! My skin tags are gone in less than 5 days. They just fell off like if they suddenly died. Obviously the affected area is still healing but there’s no sign of any scars whatsoever. I will be forever grateful!”
            </p>
        </>
    },
]